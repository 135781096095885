<template>
  <v-layout
    align-start
    :style="{ backgroundColor: color }"
    class="zg-container px-6 pt-3"
  >
    <v-icon
      v-if="hasBullet"
      style="font-size: 9px !important"
      class="mt-5 mr-2"
      color="primary"
      >mdi-circle</v-icon
    >
    <v-layout
      column
      :class="isSingleDrawingField && 'align-center'"
      style="width: 100%"
    >
      <FieldWrapperDelegate
        v-for="(field, index) in fields"
        :key="field.id"
        :field.sync="fields[index]"
        :session.sync="session"
        :patient="patient"
        :owner="owner"
        :hideHeader="index != 0"
        :hideBody="collapsed"
        :hideEdit="hideEdit"
        :disabled="
          index != 0 || !session.active || isAppointmentFinished || disabled
        "
        :class="index != 0 && !collapsed && 'mt-3'"
        :autosave="autosave"
        :name="name"
        @predefined="onPredefined"
        @collapse="onCollapse"
        @append="onAppend"
        @changed:codes="onChangedCodes"
        @copy="onCopy"
        @success="handleSuccess"
        @error="handleError"
      >
        <slot></slot>
      </FieldWrapperDelegate>
      <v-layout
        v-if="showMoreLabel && !collapsed && !isSwitchField"
        :class="isSingleDrawingField && 'align-center'"
      >
        <DefaultButton
          @click.prevent="handleLoad"
          class="mx-0"
          color="#7C7E8B"
          small
          :loading="loading"
          >{{ showMoreLabel }}</DefaultButton
        >
      </v-layout>
      <v-layout v-else class="mt-6"></v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import PatientService from "@/services/patient.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import {
  FIELD_TYPES,
  SINGLE_DRAWING_FIELDS,
} from "@/constants/patientcard/fields/types";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    fields: {},
    session: {},
    patient: {},
    owner: {},
    color: {
      type: String,
      default: "white",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autosave: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      collapsed: false,
    };
  },
  computed: {
    hasAnyFields() {
      return this.fields.length > 0;
    },
    hasBullet() {
      return this.hasAnyFields && this.fields[0].bullet;
    },
    isSingleDrawingField() {
      return (
        this.hasAnyFields &&
        SINGLE_DRAWING_FIELDS.includes(this.fields[0].field_type)
      );
    },
    isSwitchField() {
      return this.fields[0].field_type == FIELD_TYPES.SWITCH_FIELD;
    },
    canShowMore() {
      if (!this.hasAnyFields) {
        return false;
      }

      const fieldsLen = this.fields.length;
      if (fieldsLen > 2) {
        return false;
      } else {
        return true;
      }
    },
    showMoreLabel() {
      if (this.hasAnyFields) {
        const fieldsLen = this.fields.length;
        if (fieldsLen > 2) {
          return "Schowaj wpisy";
        } else {
          return "Wcześniejsze wpisy";
        }
      }
      return "";
    },
    isAppointmentFinished() {
      if (this.session.card) {
        return this.session.card.saved_in_session;
      } else {
        return true;
      }
    },
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
    FieldWrapperDelegate: () =>
      import("@/components/delegates/patientcard/FieldWrapperDelegate"),
  },
  methods: {
    handleLoad() {
      if (this.canShowMore) {
        this.showMore();
      } else {
        this.showLess();
      }
    },
    showMore() {
      this.beforeRequest();
      PatientService.getHistoryField({
        name: this.fields[0].name,
        session: this.session.id,
        type: 0,
        patient: this.patient ? this.patient.id : undefined,
        owner: this.owner ? this.owner.id : undefined,
      })
        .then((reply) => {
          const firstField = this.fields[0];
          const newFields = [firstField, ...reply.slice(1)];
          this.reset();
          this.$emit("update:fields", newFields);
        })
        .catch((error) => {
          console.log("ERROR", error, error.response);
          this.handleError("Błąd: Nie można wczytać więcej wpisów.");
        });
    },
    showLess() {
      const newFields = this.fields.slice(
        0,
        this.fields[0].field_type == FIELD_TYPES.TEXT_FIELD
          ? this.isAppointmentFinished
            ? 1
            : 2
          : 1
      );
      this.$emit("update:fields", newFields);
    },
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onCollapse() {
      this.collapsed = !this.collapsed;
      if (this.hasAnyFields) {
        localStorage[`pc_${this.fields[0].name}_collapsed`] = this.collapsed;
      }
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onChangedCodes() {
      this.$emit("changed:codes");
    },
    onCopy() {
      this.$emit("copy");
    }
  },
  mounted() {
    if (this.hasAnyFields) {
      this.collapsed =
        localStorage[`pc_${this.fields[0].name}_collapsed`] == "true";
    }
  },
};
</script>

<style scoped>
.zg-container:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>